import Vue from 'vue';
Vue.mixin({
  methods: {
    scrollToHandler: function (targetObject, event) {
      if (event) {
        event.preventDefault();
      }
      var destination =
        document.querySelector(targetObject).getBoundingClientRect().y +
        window.scrollY -
        80;
      window.scroll({
        top: destination,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
});
