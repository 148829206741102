<template>
  <div>
    <h2
      :id="id"
      :class="header_class"
    >
      {{ name }}
    </h2>
    <article class="album">
      <figure>
        <img
          :src="require(`@/assets/${src}`)"
          :alt="alt"
        >
      </figure>
      <h3 :class="header_class">
        Tracklist
      </h3>
      <ol class="tracklist">
        <li
          v-for="track in tracklist"
          :key="track"
        >
          {{ track }}
        </li>
      </ol>
      <div v-if="!comingsoon">
        <h3
          v-if="links"
          :class="header_class"
        >
          Stream / Buy
        </h3>
        <ul
          v-if="links"
          class="album_links"
        >
          <li
            v-for="link in links"
            :key="link.href"
          >
            <a
              :href="link.href"
              target="_blank"
              rel="noopener"
              :title="[link.type + ' ' + name + ' on ' + link.site.name]"
            >
              <font-awesome-icon :icon="['fab', link.site.id]" />
              <span>{{ link.site.name }}</span>
            </a>
          </li>
        </ul>
      </div>
      <button
        v-if="comingsoon"
        class="comingsoon"
        disabled
      >
        Coming {{ releasedate }}!
      </button>
    </article>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    name: String,
    id: String,
    header_class: String,
    src: String,
    alt: String,
    tracklist: Array,
    links: Object,
    comingsoon: Boolean,
    releasedate: String
  }
};
</script>

<style scoped lang="scss">
h2 {
  margin-top: 100px;
  margin-bottom: 40px;
}
h3 {
  margin: 40px 0;
  margin-bottom: 5px;
}
h2.silkweaver,
h3.silkweaver,
h2.comewithme,
h3.comewithme,
h2.forcesofnature,
h3.forcesofnature {
  font-family: 'PT Serif', serif;
  font-style: italic;
}

h2.wildfire,
h3.wildfire {
  font-family: 'Berkshire Swash', cursive;
}
h2.blackocean,
h3.blackocean {
  font-family: 'Press Start 2P', cursive;
}
h2 {
  text-align: center;
  font-size: 2rem;
  &.blackocean {
    font-size: 5rem;
  }
  @media screen and (min-width: 640px) {
    font-size: 4rem;
  }
  @media screen and (min-width: 1150px) {
    font-size: 8rem;
  }
}
h3 {
  font-size: 1rem;
  &.blackocean {
    font-size: 1rem;
  }
  @media screen and (min-width: 640px) {
    font-size: 2rem;
    &.blackocean {
      font-size: 1.5rem;
    }
  }
  @media screen and (min-width: 1150px) {
    font-size: 4rem;
    &.blackocean {
      font-size: 2rem;
    }
  }
}
ol {
  width: 100%;
  list-style-type: decimal;
  padding: 0;
  li {
    font-size: 0.8rem;
    @media screen and (min-width: 640px) {
      font-size: 1.3rem;
    }
    @media screen and (min-width: 1150px) {
      font-size: 1.8rem;
    }
  }
}
button.comingsoon {
  border: 1px solid #fff;
  padding: 10px 10%;
  border-radius: 15px;
  background: transparent;
  color: #fff;
  margin: auto;
  margin-top: 15px;
  display: block;
}
</style>
