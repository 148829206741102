<template>
  <div>
    <div id="splash">
      <img
        id="pageLogo"
        alt="Logo"
        src="../assets/logo.png"
        style="z-index: 3"
      />
      <div class="arrowDown">
        <a @click="scrollTo('#TopRelease')">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </a>
      </div>
    </div>

    <Album
      v-for="release in releases"
      :id="release.topRelease"
      :key="release.identifier"
      :name="release.name"
      :header_class="release.identifier"
      :src="release.imageSrc"
      :alt="release.imageAlt"
      :tracklist="release.tracklist"
      :links="release.links"
      :comingsoon="release.comingsoon"
      :releasedate="release.releasedate"
    />
    <div class="copyright">
      <p>&copy; 2023 Æther Fox/Æther Fox Records.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Album from '@/components/Album.vue';

export default {
  name: 'Home',
  components: {
    Album
  },
  data: function () {
    return {
      releases: [
        {
          topRelease: 'TopRelease',
          name: 'DÆMONS',
          identifier: 'daemons',
          imageSrc: 'Wildfire_CoverSmall.jpg',
          imageAlt:
            'This is the temporary album artwork for "DAEMONS" - A drawing of a fox leaving behind trails of fire in a forest.',
          tracklist: ['TBA (album art subject to change)'],
          links: [],
          comingsoon: true,
          releasedate: '2024'
        }
      ]
    };
  },
  methods: {
    scrollTo: function (targetobject, event) {
      this.scrollToHandler(targetobject, event);
    }
  }
};
</script>

<style lang="scss">
@keyframes faderLogo {
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes topArrow {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes topArrowMobile {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0px);
  }
}
#pageLogo {
  margin-top: -5vh;
  opacity: 0.6;
  max-width: 50vw;
  max-height: 50vh;
  @media screen and (min-width: 500px) and (max-height: 600px) {
    max-width: 20vw;
    max-height: 40vh;
  }
  animation: faderLogo 1s 1;
  animation-fill-mode: forwards;
}
.arrowDown {
  cursor: pointer;
  position: absolute;
  bottom: 95px;
  @media screen and (min-width: 500px) and (max-height: 600px) {
    bottom: 15px;
  }
  @media screen and (min-width: 650px) and (min-height: 600px) {
    bottom: 45px;
    @supports not (-webkit-touch-callout: none) {
      bottom: 75px;
    }
  }
  text-align: center;
  font-size: 3rem;
  animation: topArrow ease-in-out 1s infinite;
  animation-fill-mode: forwards;
}
.copyright {
  margin: auto;
  margin-top: 300px;
  height: 120px;
  display: flex;
  align-items: center;
  width: 50vw;
}
</style>
