import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '../src/mixins/scrollToHandler';
// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faYoutube,
  faInstagram,
  faBandcamp,
  faSpotify,
  faApple,
  faAmazon,
  faSoundcloud
} from '@fortawesome/free-brands-svg-icons';
library.add(
  faChevronDown,
  faTwitter,
  faYoutube,
  faInstagram,
  faBandcamp,
  faSpotify,
  faApple,
  faAmazon,
  faSoundcloud
);
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Vue Config
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
